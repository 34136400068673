import { motion } from "framer-motion";

const variants = {
    init: { opacity: 0, y: -25 },
    enter: { opacity: 1, y: 0 },
    hover: { scale: 1.1, stroke: "#fff"}
}


export const GithubLogo = () => (
    <motion.svg initial="init" animate="enter" whileHover="hover" variants={variants} xmlns="http://www.w3.org/2000/svg" width="3em" height="auto" viewBox="0 0 24 24" fill="none" stroke="#ddd" strokeWidth="1.35">
        <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
    </motion.svg>
)

export const TwitterLogo = () => (
    <motion.svg initial="init" animate="enter" whileHover="hover" variants={variants} xmlns="http://www.w3.org/2000/svg" width="3em" height="auto" viewBox="0 0 24 24" fill="none" stroke="#ddd" strokeWidth="1.35">
        <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
    </motion.svg>
)

export const DiscordLogo = () => (
    <motion.svg initial="init" animate="enter" whileHover="hover" variants={variants} xmlns="http://www.w3.org/2000/svg" width="3em" height="auto" viewBox="-1 -1 26 26" fill="none" stroke="#ddd" strokeWidth="1.35">
        <path d="M 8.414062 3.261719 C 7.078125 3.5 5.136719 4.242188 3.734375 5.046875 C 3.328125 5.28125 3.3125 5.3125 2.832031 6.382812 C 0.242188 15.375 0 16.90625 0 17.171875 C 0 17.546875 1.6875 18.894531 2.933594 19.519531 C 4.148438 20.128906 5.867188 20.652344 7.105469 20.789062 C 7.378906 20.820312 7.390625 20.816406 7.503906 20.6875 C 7.570312 20.609375 7.835938 20.171875 8.105469 19.714844 C 8.585938 18.886719 8.59375 18.882812 8.734375 18.910156 C 9.054688 18.976562 9.960938 19.078125 10.664062 19.128906 C 11.914062 19.21875 13.824219 19.140625 15.066406 18.9375 C 15.242188 18.910156 15.398438 18.898438 15.414062 18.914062 C 15.429688 18.929688 15.664062 19.328125 15.941406 19.796875 C 16.214844 20.265625 16.496094 20.6875 16.5625 20.738281 C 16.679688 20.820312 16.695312 20.828125 17.210938 20.746094 C 18.398438 20.5625 19.875 20.105469 20.929688 19.59375 C 21.933594 19.101562 23.046875 18.304688 23.738281 17.582031 L 24.007812 17.292969 L 23.976562 16.929688 C 23.859375 15.636719 23.15625 12.242188 22.613281 10.339844 C 22.15625 8.757812 21.425781 6.84375 20.789062 5.578125 C 20.628906 5.257812 20.621094 5.253906 20.152344 4.988281 C 18.609375 4.125 16.398438 3.332031 15.148438 3.207031 C 14.679688 3.15625 14.582031 3.277344 14.503906 3.976562 L 14.46875 4.273438 L 13.785156 4.234375 C 12.90625 4.1875 11.089844 4.1875 10.210938 4.234375 L 9.523438 4.273438 L 9.464844 3.839844 C 9.375 3.164062 9.277344 3.109375 8.414062 3.261719 Z M 8.769531 4.214844"/>                   
    </motion.svg>
)

export const DownArrow = () => (
    <motion.svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff">
        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
    </motion.svg>
)

export const OpenBtnPath = () => (
    <motion.svg xmlns="http://www.w3.org/2000/svg" style={{position: "relative", top: "-2px", left: 0}} width="100%" height="100%" viewBox="0 0 24 24" stroke="currentColor" fill="none">
        <motion.path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
    </motion.svg>
)
export const Hamburger = (props: any) => (
    <motion.svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </motion.svg>
);

export const XIcon = (props: any) => (
    <motion.svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </motion.svg>
);


export const TwitterFill = (props: any) => (
    <motion.svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={props.fillColor} stroke={props.fillColor}>
        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
    </motion.svg>
)
export const DiscordFill = (props: any) => (
    <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36" fill={props.fillColor} stroke={props.fillColor}>
        <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
    </motion.svg>
)

export const GithubFill = (props:any) => (
    <motion.svg xmlns="http://www.w3.org/2000/svg" style={{width: "5rem", height: "5rem"}} width="24" height="24" viewBox="-0.5 -0.5 25 25" fill={props.fillColor} stroke={props.fillColor}>
        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
    </motion.svg>
)

export const YouTubeFill = (props: any) => (
    <motion.svg xmlns="http://www.w3.org/2000/svg" style={{width: "4.5rem", height: "4.5rem"}} fill={props.fillColor} stroke={props.fillColor} width="24" height="24" viewBox="0 0 24 24">
        <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/>
    </motion.svg>
)

export const MailFill = (props: any) => (
    <motion.svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={props.fillColor} stroke={props.fillColor}>
        <path d="M22.288 21h-20.576c-.945 0-1.712-.767-1.712-1.712v-13.576c0-.945.767-1.712 1.712-1.712h20.576c.945 0 1.712.767 1.712 1.712v13.576c0 .945-.767 1.712-1.712 1.712zm-10.288-6.086l-9.342-6.483-.02 11.569h18.684v-11.569l-9.322 6.483zm8.869-9.914h-17.789l8.92 6.229s6.252-4.406 8.869-6.229z"/>
    </motion.svg>
)

export const SoundCloudFill = (props: any) => (
    <motion.svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-0.5 -0.5 25 25" fill={props.fillColor} stroke="0">
        <path d="M7 17.939h-1v-8.068c.308-.231.639-.429 1-.566v8.634zm3 0h1v-9.224c-.229.265-.443.548-.621.857l-.379-.184v8.551zm-2 0h1v-8.848c-.508-.079-.623-.05-1-.01v8.858zm-4 0h1v-7.02c-.312.458-.555.971-.692 1.535l-.308-.182v5.667zm-3-5.25c-.606.547-1 1.354-1 2.268 0 .914.394 1.721 1 2.268v-4.536zm18.879-.671c-.204-2.837-2.404-5.079-5.117-5.079-1.022 0-1.964.328-2.762.877v10.123h9.089c1.607 0 2.911-1.393 2.911-3.106 0-2.233-2.168-3.772-4.121-2.815zm-16.879-.027c-.302-.024-.526-.03-1 .122v5.689c.446.143.636.138 1 .138v-5.949z"/>
    </motion.svg>
)

export const AudiusFill = (props: any) => (
    <motion.svg width="24" height="24" viewBox="130 70 550 550" xmlns="http://www.w3.org/2000/svg" fill={props.fillColor}>
        <path d="M663.78,545.52L537.39,326.27l-59.28-102.83L411,107.02c-4.87-8.44-17.04-8.45-21.92-0.01L262.4,326.09
            l-51.76,89.51c-4.88,8.43,1.21,18.98,10.95,18.99l97.79,0.06c4.52,0,8.7-2.41,10.96-6.32l50.82-87.9l7.77-13.44
            c0.31-0.53,0.64-1.03,1-1.49c5.38-6.91,16.36-6.4,20.92,1.5l52.73,91.47l9.75,16.91c0.56,0.97,0.97,1.96,1.25,2.97
            c2.17,7.75-3.59,16.01-12.22,16l-115.04-0.08c-4.52,0-8.7,2.41-10.96,6.32l-48.95,84.65c-4.88,8.43,1.21,18.98,10.95,18.99
            l101.38,0.07l126.41,0.08l126.66,0.08C662.55,564.5,668.64,553.96,663.78,545.52z"/>
        <polygon style={{fill: "none"}} points="399.92,100 134.51,559.69 665.32,559.69 399.92,100 134.51,559.69 665.32,559.69"/>
    </motion.svg>
)