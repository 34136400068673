const songArray = [
    {
        thumb: "2020mixcover.png",
        title: "2020 mix | 12.01.20",
        desc: " a collection of both released and unreleased works i've created over the course of 2020.",
        audio: "2020mix.mp3"
    },
    {
        thumb: "klexosbg.png",
        title: "klexos | 6.06.20",
        desc: "\"maybe we should think of memory itself as a work of art- a work of art is never finished, only abandoned.\"",
        audio: "klexos.mp3"
    },
    {
        thumb: "summernightsbg.jpg",
        title: "summer nights | 8.28.19",
        desc: "relax. not a worry in the world, everything is alright. enjoy it.",
        audio: "summernights.mp3"
    },
    {
        thumb: "mantrabg.jpg",
        title: "mantra | 8.27.19",
        desc: "mantra: a word or sound repeated to aid concentration in meditation. a statement or slogan repeated frequently.",
        audio: "mantra.mp3"
    },
    {
        thumb: "nightfallbg.png",
        title: "nightfall | 7.26.19",
        desc: "it's late and starting to rain. you find a nice little cave under a waterfall to spend the night in.",
        audio: "nightfall.wav"
    },
    
]

export default songArray;