import styled from "styled-components";
import { motion } from "framer-motion";
import FadeWrap from "../components/FadeWrap"
import { MailFill, TwitterFill, DiscordFill, GithubFill, YouTubeFill, SoundCloudFill, AudiusFill } from "../components/Icons";

function Contact(props: any) {

    const buttonContainer = {
        init: {
            y: 0
        },
        animate: {
            y: 0,
            transition: {
                duration: 0.1,
                ease: "easeInOut",
                staggerChildren: 0.15
            }
        }
    }

    const buttonMotion = {
        init: {
            y: 15,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.25,
                ease: "easeInOut",
            }
        }
    }

    return(
        <FadeWrap>
            <head>
                <title>contact novadrome</title>
            </head>
            <MainCont>
                <Header>about</Header>
                <Description>
                    <span style={{letterSpacing: "0.05rem"}}><i>hey, i'm nova.</i></span><br/><br/>
                    I've been producing music for about 4-5 years, and done a variety of genres for youtubers, games, and myself.<br/>
                    I've also been pursuing (web) development for &#60;1 year, and graphic design for &#60;1 year as well.<br/>
                    I love to create things, and I strive to provide the best experience and result for my clients.
                </Description>
                <Header>contact / links</Header>
                <Description>I'm most active on Twitter and Discord, feel free to shoot me a message if you have any questions.<br/><br/><i>Email me or find me on the following platforms:</i></Description>
                <ButtonGrid initial="init" animate="animate" variants={buttonContainer}>
                    <ButtonLink bg="#EA4335" onClick={() => alert('Email will be set up soon, please contact me via another platform')} target="_blank" whileHover={{ rotateZ: 3, scale: 1.1, boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", transition: { type: "spring", bounce: 0.75, }}} variants={buttonMotion}>
                        <MailFill fillColor="#fff" />
                    </ButtonLink>
                    <ButtonLink bg="#1DA1F2" href="https://twitter.com/realnovadrome" target="_blank" whileHover={{ rotateZ: 3, scale: 1.1, boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", transition: { type: "spring", bounce: 0.75, }}} variants={buttonMotion}>
                        <TwitterFill fillColor="#fff" />
                    </ButtonLink>
                    <ButtonLink bg="#5865f2" href="https://discord.gg/ZetQ5rQA24" target="_blank" whileHover={{ rotateZ: 3, scale: 1.1, boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", transition: { type: "spring", bounce: 0.75, }}} variants={buttonMotion}>
                        <DiscordFill fillColor="#fff" />
                    </ButtonLink>
                    <ButtonLink bg="#000000" href="https://github.com/novadrome" target="_blank" whileHover={{ rotateZ: 3, scale: 1.1, boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", transition: { type: "spring", bounce: 0.75, }}} variants={buttonMotion}>
                        <GithubFill fillColor="#fff" />
                    </ButtonLink>
                    <ButtonLink bg="#FF0000" href="https://youtube.com/officialnovadrome" target="_blank" whileHover={{ rotateZ: 3, scale: 1.1, boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", transition: { type: "spring", bounce: 0.75, }}} variants={buttonMotion}>
                        <YouTubeFill fillColor="#fff" />
                    </ButtonLink>
                    <ButtonLink bg="#FF7F34" href="https://soundcloud.com/novadrome" target="_blank" whileHover={{ rotateZ: 3, scale: 1.1, boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", transition: { type: "spring", bounce: 0.75, }}} variants={buttonMotion}>
                        <SoundCloudFill fillColor="#fff" />
                    </ButtonLink>
                    <ButtonLink bg="#9E1DCF" href="https://audius.co/novadrome" target="_blank" whileHover={{ rotateZ: 3, scale: 1.1, boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", transition: { type: "spring", bounce: 0.75, }}} variants={buttonMotion}>
                        <AudiusFill fillColor="#fff" />
                    </ButtonLink>
                </ButtonGrid>
            </MainCont>
        </FadeWrap>
    )
}

const MainCont = styled(motion.div)`
    scrollbar-width: none;  
    width: 70%;
    height: auto;
    padding-top: 4rem;
    margin-left: 11rem;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: visible;

    @media(max-width: 850px){
        margin-top: 2rem;
        width: 100%;
        margin-left: 5%;
        overflow-x: hidden;
        text-align: center;
    }
`

const Header = styled.div`
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1em 0;

    @media(max-width: 850px){
        width: 90%;
    }
`

const Description = styled.div`
    font-size: 1.75rem;
    width: 90%;
    margin-bottom: 2rem;
    width: calc(100% - 11rem);

    @media(max-width: 850px){
      width: 90%;
    }
`

const ButtonGrid = styled(motion.div)`
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 11rem);
    margin-bottom: 2rem;

    @media (max-width: 850px){
        flex-direction: column;
        width: 85%;
    }
`

const ButtonLink = styled(motion.a)<{bg:string}>`
    margin: 1rem;
    width: 8rem;
    height: 8rem;
    background-color: ${({bg}) => bg};
    border-radius: 1em;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
        width: 4rem;
        height: 4rem;
    }

    @media (max-width: 850px){
        width: 100%;
    }
    
`
export default Contact;
