import styled from "styled-components";
import { motion } from "framer-motion";
import FadeWrap from "../components/FadeWrap"

// TODO: convert all art to .webp, compress 


function Art(props: any) {

    return(
        <FadeWrap>
            <head>
                <title>novadrome's music</title>
            </head>
            <Container>
                <Row>
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/caerulus4k.png" />
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/CyclesXTest2.png" />
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/Day6Otherworldly_Glow.png" />
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/Day12LilacComposite.png" />
                </Row>
                <Row>
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/Day13CentroidWithLCD.png" />
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/stairway1.png" />
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/Day7GlassDispersionMarbleV2Centered.png" />
                </Row>
                <Row>
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/iridis4k.png" />
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/Liquify1.png" />
                    <Image whileHover={{transition: {duration: 0.25, ease: "easeInOut"}, scale: 1.1}} loading="lazy" draggable="false" src="/art/Day9Canyon.png" />
                </Row>
            </Container>
        </FadeWrap>
    )
}

export default Art;

const Container = styled(motion.div)`
    scrollbar-width: none;  
    width: calc(100% - 22.5rem);
    height: 100%;
    padding-top: 5rem;
    margin-left: 3rem;

    display: flex;
    flex-flow: row wrap;
    margin-bottom: 5rem;
    justify-content: center;

    @media (max-width: 850px) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-left: -0.75rem;
    }
`
const Row = styled(motion.div)`
    width: 35rem;
    min-width: 300px;
    height: auto;

    display: flex;
    flex-direction: column;
`

const Image = styled(motion.img)`
    margin: 2rem;
    // &:hover {
    //     cursor: pointer;
    // }
`