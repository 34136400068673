import styled from "styled-components";
import { motion } from "framer-motion";
import FadeWrap from "../components/FadeWrap"
import { OpenBtnPath } from "../components/Icons";

function Dev() {

    const projectFlex = {
        start: {
            opacity: 0, 
        },
        load: {
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.2,
                staggerChildren: 0.2
            }
        },
    }

    const btnHover = {
        hover: {
            transition: {
                ease: "easeInOut", 
                duration: 0.25,
            },
            boxShadow: "-3px 3px 5px -0px rgba(0, 0, 0, 0.15)", 
            color: "#3c5fc7"
        },
    }

    const projectContainer = {
        start: {
            opacity: 0,
            border: "0",
            boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1)", 
            y: 25
        },
        load: {
            opacity: 1,
            y: 0
        }
    }

    function hoverStart(langColor:string, id:string) {
        let project = document.getElementById(id)!;
        let bubble = document.getElementById("bubble" + id)!;

        bubble.style.color = langColor;
        bubble.style.top = project.offsetTop.toString() + "px";
        bubble.style.left = (project.offsetLeft + (project.clientWidth/2)).toString() + "px";
        bubble.style.opacity = "1";
    }

    function hoverEnd(id:string) {
        let bubble = document.getElementById("bubble" + id)!;
        bubble.style.opacity = "0";
    }

    return(
        <FadeWrap>

            {/* LISTEN. IM SURE THERE IS A BETTER WAY TO DO THIS. I AM TIRED AND WE'RE CALLING IT DONE. IF YOU HAVE A BETTER METHOD PLEASE LET ME KNOW */}

            <LangBubbleCont id="bubble1" className="langBubble">
                <LangBubble bg="#9700E9">
                    react + typescript
                </LangBubble>
                <LangBubbleTri bg="#9700E9" />
            </LangBubbleCont>

            <LangBubbleCont id="bubble2" className="langBubble">
                <LangBubble bg="#fcd703">
                    javascript
                </LangBubble>
                <LangBubbleTri bg="#fcd703" />
            </LangBubbleCont>

            <LangBubbleCont id="bubble3" className="langBubble">
                <LangBubble bg="#f00">
                    html + css
                </LangBubble>
                <LangBubbleTri bg="#f00" />
            </LangBubbleCont>

            <LangBubbleCont id="bubble4" className="langBubble">
                <LangBubble bg="#9700E9">
                    react + typescript
                </LangBubble>
                <LangBubbleTri bg="#9700E9" />
            </LangBubbleCont>

            <Container>
                <Header>projects i've done</Header>
                <ProjectFlex initial="start" animate="load" variants={projectFlex}>
                    <ProjectContainer 
                        id="1" 
                        onHoverStart={() => hoverStart("#ff0000", "1")} 
                        onHoverEnd={() => hoverEnd("1")} 
                        whileHover={{transition: {
                            ease: "easeInOut", 
                            duration: 0.25
                        },
                        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", 
                        stroke: "#77a"}} 
                        variants={projectContainer}
                    >
                        <Title>
                            eton
                            <motion.a target="blank" href="https://novadrome.github.io/eton">
                                <ButtonCont whileHover="hover" variants={btnHover}>
                                    <OpenBtnPath />
                                </ButtonCont>
                            </motion.a>
                        </Title>
                        <Description>time oriented note-taking</Description>
                    </ProjectContainer>
                    <ProjectContainer 
                        id="2" 
                        onHoverStart={() => hoverStart("#ff0000", "2")} 
                        onHoverEnd={() => hoverEnd("2")} 
                        whileHover={{transition: {
                            ease: "easeInOut", 
                            duration: 0.25
                        },
                        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", 
                        stroke: "#77a"}} 
                        variants={projectContainer}
                    >
                        <Title>
                            hdri-hub-scraper
                            <motion.a target="blank" href="https://github.com/novadrome/hdri-hub-scraper">
                                <ButtonCont  whileHover="hover" variants={btnHover}>
                                    <OpenBtnPath />
                                </ButtonCont>
                            </motion.a>
                        </Title>
                        <Description>Script that uses a reverse engineering workaround to download a full resolution HDRI from hdri-hub.com for free</Description>
                    </ProjectContainer>
                    <ProjectContainer 
                        id="3" 
                        onHoverStart={() => hoverStart("#ff0000", "3")} 
                        onHoverEnd={() => hoverEnd("3")} 
                        whileHover={{transition: {
                            ease: "easeInOut", 
                            duration: 0.25
                        },
                        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", 
                        stroke: "#77a"}} 
                        variants={projectContainer}
                    >
                        <Title>
                            novadrome.github.io
                            <motion.a target="blank" href="https://novadrome.github.io">
                                <ButtonCont whileHover="hover" variants={btnHover}>
                                    <OpenBtnPath />
                                </ButtonCont>
                            </motion.a>
                        </Title>
                        <Description>This will primarily serve as a website for me to host my current and future projects on.</Description>
                    </ProjectContainer>
                    <ProjectContainer 
                        id="4" 
                        onHoverStart={() => hoverStart("#ff0000", "4")} 
                        onHoverEnd={() => hoverEnd("4")} 
                        whileHover={{transition: {
                            ease: "easeInOut", 
                            duration: 0.25
                        },
                        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", 
                        stroke: "#77a"}} 
                        variants={projectContainer}
                    >
                        <Title>
                            officialnovadrome.com
                            <motion.a target="blank" href="https://officialnovadrome.com">
                                <ButtonCont whileHover="hover" variants={btnHover} >
                                    <OpenBtnPath />
                                </ButtonCont>
                            </motion.a>
                        </Title>
                        <Description>My portfolio website, that you're on right now!</Description>
                    </ProjectContainer>
                    <ProjectContainer 
                        variants={projectContainer}
                        whileHover={{transition: {
                            ease: "easeInOut", 
                            duration: 0.25
                        },
                        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.3)", 
                        stroke: "#77a"}}
                    >
                        <Title>
                            ...and more soon!
                        </Title>
                        <Description>I'm always working on something, whether it be for myself or to release publicly.</Description>
                    </ProjectContainer>
                </ProjectFlex>
            </Container>
        </FadeWrap>
    )
}

export default Dev;

const Container = styled(motion.div)`
    scrollbar-width: none;  
    width: fit-content;
    height: auto;
    padding-top: 4rem;
    margin-left: 5rem;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: visible;

    @media(max-width: 850px){
        width: 100%;
        margin-left: 0;
        overflow-y: scroll;
    }
`

const Header = styled(motion.h1)`
    height: auto;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 4rem;
    width: calc(100% - 22.5rem);
    user-select: none;
    @media (max-width: 850px){
        width: 100%;
    }
`
const ProjectFlex = styled(motion.div)`
    display: flex;
    flex-flow: row wrap;
    height: auto;
    width: calc(100% - 22.5rem);
    justify-content: center;

    @media (max-width: 850px){
        width: 100%;
    }
`
const ProjectContainer = styled(motion.div)`
    background-color: #fff;
    border: 0;
    border-radius: 1.5rem;
    padding: 2rem;
    width: 26rem;
    min-width: 26rem;
    height: 7rem;
    margin: 1.75rem;
    user-select: none;
`

const Title = styled(motion.div)`
    width: 100%;
    height: auto;

    margin-top: -0.5rem;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const Description = styled(motion.div)`
    width: 100%;
    height: 3rem;
    word-wrap: break-word;

    font-size: auto;
    font-weight: 300;
`
const ButtonCont = styled(motion.div)`
    width: 30px;
    height: 30px;
    padding: 0.25rem;
    border-radius: 0.5rem;
    color: #000;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    &:hover {
        cursor: pointer;
    }
`
const LangBubbleCont = styled(motion.div)`
    position: absolute;
    width: auto;
    height: auto;
    transform: translate(-50%, -65%);
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    user-select: none;
`

const LangBubble = styled(motion.div)<{ bg: string }>`
    width: auto;
    height: 100%;
    border-radius: 1rem;
    background-color: ${ props => props.bg };
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    padding: 0.75rem 1.5rem 1.25rem 1.5rem;
`

const LangBubbleTri = styled(motion.div)<{ bg: string }>`
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: solid 15px ${ props => props.bg };
    margin: 0 auto;
`


