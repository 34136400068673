import styled from "styled-components";
import { motion } from "framer-motion";
import FadeWrap from "../components/FadeWrap"
import Song from "../components/Song"
import songArray from "../commissionArray";

function Commission() {

    const songsContainer = {
        init: {
            opacity: 0
        },
        load: {
            opacity: 1,
            transition: {
                staggerChildren: 0.25
            }
        }
    }

    const songItems = {
        init: {
            opacity: 0,
            y: -25,
            boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1)"
        },
        load: {
            opacity: 1,
            y: 0,
        }
    }

    return(
        <FadeWrap>
            <head>
                <title>novadrome's projects</title>
            </head>
            <MainCont>
                <Summary>
                    <Paragraph>
                    Hello, I do music commissions.<br/><br/>
                    Past works of mine have included orchestral, edm, lofi, SNES and retro music.<br/><br/>
                    I make an effort to keep my prices somewhat cheap, but I still offer the same quality and service that you would find elsewhere.<br/><br/>
                    When you purchase a commission, I work with you on the track to make sure it is exactly what you had in mind when you purchased.<br/><br/>
                    Past works of mine are shown below.
                    </Paragraph>
                    <Pricing src="/commissions/pricing.png" />
                </Summary>
                <SongList 
                initial="init" 
                animate="load" 
                variants={songsContainer}>
                    {songArray.map(s => <Song 
                    thumb={"/commissions/thumbs/" + s.thumb} 
                    audio={"/commissions/songs/" + s.audio} 
                    title={s.title} 
                    desc={s.desc}
                    variants={songItems}
                    />)}
                </SongList>
            </MainCont>
        </FadeWrap>
    )
}

const MainCont = styled(motion.div)`
    scrollbar-width: none;  
    width: 65%;
    height: auto;
    padding-top: 4rem;
    margin-left: 13rem;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: visible;

    @media(max-width: 850px){
        margin-top: 2rem;
        width: 100%;
        margin-left: 0;
    }
`

const Summary = styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: auto;
    margin-bottom: 3rem;
`

const Paragraph = styled.p`
    width: 45%;
    height: auto;
    margin-right: 3rem;
    font-size: 1.75rem;

    @media(max-width: 1518px){
        text-align: center;
        margin: 2rem 0 3rem 25%;
        width: 50%;
    }

    @media(max-width: 850px){
        margin-bottom: 3rem;
        margin-left: 5%;
        text-align: center;
        width: 90%;
    }
`

const Pricing = styled.img`
    width: 40%;
    min-width: 34rem;
    height: 100%;
    margin-left: auto;
    border-radius: 5px;

    @media(max-width: 1518px) and (min-width: 850px) {
        margin: 2rem 0 3rem 25%;
        width: 40%;
    }

    @media(max-width: 850px) {
        margin-bottom: 3rem;
        min-width: 25rem;
        margin-left: 5%;
        width: 90%;
    }
`

const SongList = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    @media(max-width: 850px){
        width: 90%;
        margin-left: 5%;
    }
`


export default Commission;
