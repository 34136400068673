import { motion } from "framer-motion";
import { ReactElement } from "react";
import styled from "styled-components";

const FadeWrap = ({ children }: {children: ReactElement | ReactElement[]}) => {

    const pageVariants = {
        initial: {
            opacity: 0,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        },
    };
      
    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.4,
    };

    return (
        <Wrap
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            style={{zIndex: 0}}
            key="pagewrapper"
        >
            {children}
        </Wrap>
    );
};

const Wrap = styled(motion.div)`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;

    @media (max-width: 850px) {
        overflow-x: hidden;
    }
`

export default FadeWrap;