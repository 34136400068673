import styled from "styled-components";
import { motion } from "framer-motion";

interface songProps {
    thumb:string, 
    audio:string, 
    title:string, 
    desc?:string,
    variants:any
}

const Song = (props:songProps) => {

    return (
        <Container whileHover={{boxShadow: "0 25px 50px -12px rgba(0,0,0,0.5)"}} variants={props.variants}>
            <Image src={props.thumb} />
            <Details>
                <Title>{props.title}</Title>
                <Desc>{props.desc}</Desc>
                <Audio src={props.audio} controls loop controlsList="nodownload"/>
            </Details>
        </Container>  
    )
}

export default Song;

const Container = styled(motion.div)`
    display: flex; 
    flex-direction: row;
    width: 100%;
    height: 15rem;
    margin-bottom: 2.25rem;
    border-radius: 10px;
    background: #ffffff;

    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
`

const Image = styled.div<{ src: string }>`
    margin: 1.25rem;
    width: 15rem;
    height: 12.5rem;
    background: url("${({src}) => src}");
    background-position: 50% 50%;
    background-size: cover;
    flex-shrink: 0;
    border-radius: 10px;
`

const Details = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media(max-width: 850px){
        margin-left: 0.25rem;
    }
`

const Title = styled.h1`
    width: 100%;
    height: auto;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 300;
    @media(max-width: 850px){
        font-size: 1.55rem;
    }
`

const Desc = styled.p`
    width: 100%;
    height: auto;
    margin: 0 0 1rem 0.6rem;
    font-weight: 300;
    font-size: 1.25rem;
    @media(max-width: 850px){
        font-size: 1rem;
    }
`

const Audio = styled.audio`
    display: block;
    margin-top: auto;
    margin-bottom: 1.25rem;
    height: 2.5rem;
    width: calc(100% - 1.25rem);
    flex-shrink: 0;
    border-radius: 10px;
    background: #e6e6e6;
`