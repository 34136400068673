import styled from "styled-components";
import { motion } from "framer-motion";
import FadeWrap from "../components/FadeWrap"
import Song from "../components/Song"
import songArray from "../songArray";

function Music(props: any) {

    const songsContainer = {
        init: {
            opacity: 0
        },
        load: {
            opacity: 1,
            transition: {
                staggerChildren: 0.25
            }
        }
    }

    const songItems = {
        init: {
            opacity: 0,
            y: -25,
            boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1)"
        },
        load: {
            opacity: 1,
            y: 0,
        }
    }

    return(
        <FadeWrap>
            <head>
                <title>novadrome's projects</title>
            </head>
            <MainCont>
                <Header>original tracks</Header>
                <SongList 
                initial="init" 
                animate="load" 
                variants={songsContainer}>
                    {songArray.map(s => <Song 
                    thumb={"/music/thumbs/" + s.thumb} 
                    audio={"/music/songs/" + s.audio} 
                    title={s.title} 
                    desc={s.desc}
                    variants={songItems}
                    />)}
                </SongList>
            </MainCont>
        </FadeWrap>
    )
}

const MainCont = styled(motion.div)`
    scrollbar-width: none;  
    width: 65%;
    height: auto;
    padding-top: 4rem;
    margin-left: 13rem;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: visible;

    @media(max-width: 850px){
        margin-top: 2rem;
        width: 100%;
        margin-left: 0;
    }
`

const Header = styled(motion.h1)`
    height: auto;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 4rem;
    width: 100%;
    user-select: none;

    @media (max-width: 850px){
        width: 100%;
    }
`

const SongList = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    @media(max-width: 850px){
        width: 90%;
        margin-left: 5%;
    }
`


export default Music;
