const songArray = [
  {
    thumb: "thekrakenstirs.jpg",
    title: "The Kraken Stirs | 3.1.22",
    desc: "Commissioned by Abyssoft",
    audio: "thekrakenstirs.mp3",
  },
  {
    thumb: "smokininthe80s.jpg",
    title: "Smoking in the 80's | 10.13.21",
    desc: "Commissioned by Abyssoft",
    audio: "smokininthe80s.mp3",
  },
  {
    thumb: "resembledpeace.jpg",
    title: "Resembled Peace | 7.29.21",
    desc: "Commissioned by Abyssoft",
    audio: "resembledpeace.mp3",
  },
  {
    thumb: "nighthawk.jpg",
    title: "Nighthawk | 7.03.21",
    desc: "Commissioned by Abyssoft",
    audio: "abyssnighthawk.mp3",
  },
  {
    thumb: "UndergroundNeonMageDuel.jpg",
    title: "Underground Neon Mage Duel | 5.26.21",
    desc: "Commissioned by Abyssoft",
    audio: "UndergroundNeonMageDuel.mp3",
  },
  {
    thumb: "monstrominuet.png",
    title: "The Monstro Minuet | 4.09.21",
    desc: "Commissioned by Abyssoft",
    audio: "MonstroMinuet.mp3",
  },
  {
    thumb: "aceinthehole.jpg",
    title: "Ace in the Hole | 3.29.21",
    desc: "Commissioned by Abyssoft",
    audio: "AceInTheHole.mp3",
  },
  {
    thumb: "meteorcitymystery.jpg",
    title: "Meteor City Mystery | 3.18.21",
    desc: "Commissioned by Abyssoft",
    audio: "MeteorCityMystery.mp3",
  },
  {
    thumb: "abyssupbeatretro.png",
    title: "untitled | 12.15.20",
    desc: "Commissioned by Abyssoft",
    audio: "abyssupbeatretro.mp3",
  },
  {
    thumb: "abyssnightlapseretro.jpg",
    title: "night vibes | 11.28.20",
    desc: "Commissioned by Abyssoft",
    audio: "abyssnightlapseretro.mp3",
  },
  {
    thumb: "unnamedrock.jpg",
    title: "unnamed (rock) | 10.13.20",
    desc: "Commissioned by Abyssoft",
    audio: "abyssrockmontage.mp3",
  },
  {
    thumb: "abyssfinale.jpg",
    title: "finale | 8.19.20",
    desc: "Commissioned by Abyssoft",
    audio: "abyssfinale.mp3",
  },
  {
    thumb: "abyssinformative.jpg",
    title: "informative | 7.23.20",
    desc: "Commissioned by Abyssoft",
    audio: "abyssinformative.mp3",
  },
  {
    thumb: "abyssintro.jpg",
    title: "intro | 7.19.20",
    desc: "Commissioned by Abyssoft",
    audio: "abyssintro.mp3",
  },
  {
    thumb: "abyssunnamed.jpg",
    title: "unnamed | 5.10.20",
    desc: "Commissioned by Abyssoft",
    audio: "abyssunnamed.mp3",
  },
  {
    thumb: "asyouwish.png",
    title: "As You Wish [Remix] | 2.11.20",
    desc: "Commissioned by Abyssoft",
    audio: "asyouwish.wav",
  },
  {
    thumb: "earthboundbg.jpg",
    title: "Winters' White [Remix] | 2.09.20",
    desc: "Commissioned by Abyssoft",
    audio: "winterswhite.mp3",
  },
  {
    thumb: "abyssoft.jpg",
    title: "ease | 1.25.20",
    desc: "Commissioned by Abyssoft",
    audio: "ease.mp3",
  },
  {
    thumb: "latixer.jpg",
    title: "Unnamed | 4.11.19",
    desc: "Commissioned by Latixer",
    audio: "LatixerUnnamed.mp3",
  },
  {
    thumb: "space.jpg",
    title: "novadrome - space | 3.11.19",
    desc: "Commissioned by Pondaz",
    audio: "Space.mp3",
  },
  {
    thumb: "tanooki.png",
    title: "TanookiTunes | 2.21.19",
    desc: "Commissioned by TanookiAlex",
    audio: "TanookiTunes.mp3",
  },
  {
    thumb: "night.jpg",
    title: "Shoveling Simulator OST - Night | 7.19.18",
    desc: "Commissioned by sauce1290",
    audio: "Night.mp3",
  },
];

export default songArray;
