import './App.scss';
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useState } from 'react';

import Nav from "./components/Nav"
import Home from "./pages/Home";
import Music from "./pages/Music";
import Commissions from "./pages/Commissions";
import Art from "./pages/Art";
import Dev from "./pages/Dev";
import Contact from "./pages/Contact";

function App() {

  const pathname:string = window.location.pathname;

  const [homeActive, setHomeActive] = useState(pathname === "/" ? true : false);

  function homeDisable() {
    setHomeActive(false);
  }
  function homeEnable() {
    setHomeActive(true);
  }

  return (
    <Wrapper>
      <Router>

        <motion.section transition={{ duration: 0.85 }} animate={{ y: homeActive ? 0 : -window.innerHeight }} > 
          <Home btnMoreClick={homeDisable} />
        </motion.section>

        <Pages id="pageContainer" 
          transition={{ duration: 0.85 }}
          initial={{ y: homeActive ? window.innerHeight : 0 }}
          animate={{ y: homeActive ? window.innerHeight : 0 }}>

          <Nav />
          <PageWrapper>
            <AnimatePresence>
              <Switch>
                <Route exact path="/music" component={Music} />
                <Route exact path="/commissions" component={Commissions} />
                <Route exact path="/art" component={Art} />
                <Route exact path="/dev" component={Dev} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/">
                  {homeEnable}
                </Route>
              </Switch>
            </AnimatePresence>
          </PageWrapper>
        </Pages>  
      </Router>
    </Wrapper>
  );
}

export default App;

const Wrapper = styled.div`
  position: absolute; 
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;

  background: linear-gradient(180deg, rgba(41,50,163,1) 0%, rgba(96,86,174,1) 25%, rgba(132,101,166,1) 56%, rgba(180,128,135,1) 85%, rgba(183,153,157,1) 92%, rgba(182,175,189,1) 100%);
`;

const Pages = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #e6e6e6;

  display: flex;
  flex-direction: row;
`

const PageWrapper = styled(motion.div)`
  z-index: 0;
  width: 100%;
  height: 100%;
  color: #000;
`