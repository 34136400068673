import { motion } from "framer-motion";
import styled from "styled-components";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { XIcon, Hamburger} from "../components/Icons";


const Nav = () => {

    //ADD THEME BUTTON AT TOP LEFT, MAKE DARK AND LIGHT THEME


    const { pathname } = useLocation();

    const barOffsets: { [key: string]: string|number } = {
        "/music": "0.25rem",
        "/commissions": "3.6rem",
        "/art": "6.9rem",
        "/dev": "10.1rem",
        "/contact": "13.4rem",
        "/": "16.5rem",
    };

    const link = {
        init: { opacity: 0},
        enter: {
            opacity: 1,
            transition: {
                duration: 0.5,
                ease: [0.48, 0.15, 0.25, 0.96],
                staggerChildren: 0.25,
            },
        },
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function windowResize(){
            setIsMobile(window.innerWidth <= 850);
        }

        window.addEventListener("resize", windowResize);
        windowResize();
        return () => window.removeEventListener("resize", windowResize);
    }, []);

    const [openOnMobile, setOpenOnMobile] = useState(false);

    const toggleMobileMenu = () => {
        if(isMobile) setOpenOnMobile(!openOnMobile)
    }

    return (
        <Container openOnMobile={openOnMobile}>
            <MobileContainer>
                {openOnMobile ? <XIcon onClick={toggleMobileMenu} /> : <Hamburger onClick={toggleMobileMenu} />}
            </MobileContainer>
            
            <NavContainer openOnMobile={openOnMobile}>
                <Bar openOnMobile={openOnMobile} animate={{y: (barOffsets[pathname])}}/>
                <LinkCont variants={link} whileHover={{x: 10}}>
                    <StyledLink to="/music" onClick={toggleMobileMenu}>
                        <FakeHref href="/#">music</FakeHref>
                    </StyledLink>
                </LinkCont>
                <LinkCont variants={link} whileHover={{x: 10}}>
                    <StyledLink to="/commissions" onClick={toggleMobileMenu}>
                        <FakeHref href="/#">commissions</FakeHref>
                    </StyledLink>
                </LinkCont>
                <LinkCont variants={link} whileHover={{x: 10}}>
                    <StyledLink to="/art" onClick={toggleMobileMenu}>
                        <FakeHref href="/#">art</FakeHref>
                    </StyledLink>
                </LinkCont>
                <LinkCont variants={link} whileHover={{x: 10}}>
                    <StyledLink to="/dev" onClick={toggleMobileMenu}>
                        <FakeHref href="/#">dev</FakeHref>
                    </StyledLink>
                </LinkCont>
                <LinkCont variants={link} whileHover={{x: 10}}>
                    <StyledLink to="/contact" onClick={toggleMobileMenu}>
                        <FakeHref href="/#">contact</FakeHref>
                    </StyledLink>
                </LinkCont>
                <LinkCont variants={link} whileHover={{x: 10}}>
                    <StyledLink to="/" onClick={toggleMobileMenu}>
                        <FakeHref href="/#">⏎ home</FakeHref>
                    </StyledLink>
                </LinkCont>
            </NavContainer>
        </Container>
    );
};

export default Nav;

const Container = styled.div<{ openOnMobile: boolean }>`
    width: 17rem;
    min-width: 200px;
    height: 100vh;
    z-index: 90;

    @media (max-width: 850px){
        // width: 100%;
        position: fixed;
        width: ${({openOnMobile}) => openOnMobile ? '100%' : '0'};
    }
`;

const MobileContainer = styled.div`
    display: block;
    width: 100%;
    height: auto;
    color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    padding: 1em;
    z-index: 500;

    @media(min-width: 850px){
        display: none;
    }

    &:hover {
        pointer: cursor;
        color: #222;
    }
`;

const NavContainer = styled(motion.div)<{ openOnMobile: boolean }>`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    width: 17rem;
    height: auto;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;

    font-size: 2rem;
    color: #000;

    & > div {
        width: 12rem;
        margin: 0 0 0.63rem 1rem;
        color: #000;
    }

    z-index: 10;

    @media (max-width: 850px){
        display: ${({openOnMobile}) => openOnMobile ? 'flex' : 'none'};
        width: 100%;
        height: 100%;
        font-size: 3rem;
        background: #e6e6e6;
        align-items: center;
        justify-content: center;
        & > div {
            width: auto;
        }
    }

`;

const LinkCont = styled(motion.div)`
    width: auto;
`

const StyledLink = styled(Link)`
    user-select: none;
    text-decoration: none;
    color: #000;
    width: text;
`

const FakeHref = styled.a`
    text-decoration: none;
    color: #000;

`

const Bar = styled(motion.svg)<{ openOnMobile: boolean }>`
    position: absolute;
    top: 0.3rem;
    height: 2rem;
    width: 0.15rem;
    background-color: #000;

    left: 0.75em;
    @media (max-width: 850px){
        display: none;
    }
`;