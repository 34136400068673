import styled from "styled-components";
import { motion } from "framer-motion";
import { GithubLogo, TwitterLogo, DiscordLogo, DownArrow } from "../components/Icons";
import { Link } from "react-router-dom";

function Home(props: any) {

    const header = {
        init: { opacity: 0},
        enter: {
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.48, 0.15, 0.25, 0.96],
            staggerChildren: 0.25,
          },
        },
      };
    
      const items = {
        init: { opacity: 0 },
        enter: { opacity: 1, 
          transition: {
            ease: "easeInOut",
            duration: 0.25,
          }
        },
      }

      const icons = {
        init: { opacity: 0, translateY: -15 },
        enter: { opacity: 1, transform: "none", translateY: 0,
          transition: {
            ease: "easeInOut",
            duration: 0.5,
          }
        },
      }

    return(
        <Wrapper>
            <head>
                <title>novadrome's portfolio</title>
            </head>
            <IconContainer initial="init" animate="enter" variants={icons}>
                <a href="https://github.com/novadrome">
                    <GithubLogo />
                </a>
                <a href="https://twitter.com/realnovadrome">
                    <TwitterLogo />
                </a>
                <a href="https://discord.gg/ZetQ5rQA24">
                    <DiscordLogo />
                </a>
            </IconContainer>
            
            <MainContainer initial="init" animate="enter" variants={header}>
                <TextContainer>
                    <TitleHeading variants={items}>novadrome</TitleHeading>
                    <Subheading variants={items}>music producer, developer, graphic designer</Subheading>
                    <Link to="/music">
                        <More onClick={props.btnMoreClick} 
                        variants={items} 
                        whileTap={{transition: {ease: "easeInOut", duration: 0.1}, backgroundColor: "rgba(189, 189, 189, 0.5)"}} 
                        whileHover={{ 
                            transition: {ease: "easeInOut", duration: 0.25}, 
                            backgroundColor: "rgba(189, 189, 189, 0.15)", 
                            boxShadow: "-0.1em -0.1em 0.1em 0.1em rgba(0, 0, 0, 0.15)" 
                        }}>
                                more<DownArrow />
                        </More>
                    </Link>
                </TextContainer>
            </MainContainer>
        </Wrapper>
    )
}

export default Home;

const Wrapper = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`

const MainContainer = styled(motion.div)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: row;
    @media (max-width: 850px) {
        margin-top: 2em;
        flex-direction: column;
        height: auto;
    }

    width: auto;
    height: 21%;
    min-height: 210px;

    img {
        margin-right: 2em;
    }
`

const TextContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 850px) {
        text-align: center;
    }
`

const TitleHeading = styled(motion.h1)`
    position: relative;  
    top: -0.50em;
    margin: 0 0 -0.25em -0.05em;
    font-size: 5em;
    font-weight: 600;
    -webkit-text-fill-color: rgba(0, 0, 0, 0); /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;

    @media (max-width: 850px) {
        position: relative;
        font-size: 4em;
    }
`

const Subheading = styled(motion.h2)`
    margin: 0;
    margin-bottom: 2.3em;

    font-size: 1.6em;
    font-weight: 300;

    overflow: visible;
    white-space: nowrap;
    @media (max-width: 850px) {
        overflow: visible;
        white-space: normal;
    }
`

const More = styled(motion.button)`
    background-color:rgba(189, 189, 189, 0);
    border: none;
    border-radius: 0.75em;
    color: #fff;
    font-size: 1.35em;
    font-weight: 300;

    margin-left: -1em;
    margin-bottom: auto;

    width: 6em;
    height: auto;
    padding: 0.6em;
    box-shadow: 0em 0em 0em 0em rgba(0, 0, 0, 0);

    &:hover {
        cursor: pointer;
    }

    & > svg {
        margin-left: 0.5em;
        margin-bottom: -0.25em;
    }

    @media (max-width: 850px) {
        width: auto;
        text-align: center;
    }
`

const IconContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    padding: 1.75em;

    svg {
        margin-left: 1.25em;
    }

    width: auto;
    height: 6em;
    justify-content: right;
    float: right;
`
